export default class LoggableError {
    public header: String
    public body: String
    public expanded: boolean;

    constructor(init?: Partial<LoggableError>) {
        this.header = "";
        this.body = "";
        this.expanded = false;
        Object.assign(this, init);
    }
}