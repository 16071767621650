import {store} from "@/store/store-decorators";
import {RootStore} from "@/store/RootStore";
import ProductionOrder from "@/domain/production/ProductionOrder";
import {reactive} from "vue";
import SalesOrder from "@/domain/production/SalesOrder";
import ProposalSimpleDTO from "@/dto/ProposalSimpleDTO";
import ProductionOrderAssembly from "@/domain/production/ProductionOrderAssembly";

type State = {
    salesOrders: Array<SalesOrder>;
    productionOrders: Array<ProductionOrder>;
    productionOrderAssemblies: Array<ProductionOrderAssembly>;
    proposalDTOs: Array<ProposalSimpleDTO>
}

@store
export default class ProductionStore {
    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    private state = reactive<State>({
        salesOrders: [],
        productionOrders: [],
        productionOrderAssemblies: [],
        proposalDTOs: []
    })

    get salesOrders(): Array<SalesOrder> {
        return this.state.salesOrders;
    }

    set salesOrders(value: Array<SalesOrder>) {
        this.state.salesOrders = value;
    }

    get productionOrders(): Array<ProductionOrder> {
        return this.state.productionOrders;
    }

    set productionOrders(value: Array<ProductionOrder>) {
        this.state.productionOrders = value;
    }

    get productionOrderAssemblies(): Array<ProductionOrderAssembly> {
        return this.state.productionOrderAssemblies;
    }

    set productionOrderAssemblies(value: Array<ProductionOrderAssembly>) {
        this.state.productionOrderAssemblies = value;
    }

    get proposalDTOs(): Array<ProposalSimpleDTO> {
        return this.state.proposalDTOs;
    }

    set proposalDTOs(value: Array<ProposalSimpleDTO>) {
        this.state.proposalDTOs = value;
    }
}