

import {defineComponent, watch} from "vue";

export default defineComponent({
    name: 'checkbox-input',
    components: {},
    props: {
        modelValue: {
            type: Boolean,
        },
        cols: String,
        lg: String,
        md: String,
        sm: String,
        xs: String,
        label: String,
        labelTop: {
          type: Boolean,
          value: false
        },
        disabled: Boolean,
        moreInfo: String,
        inlineLeft: Boolean,
        inlineRight: Boolean,
        readonly: Boolean,
        radioLabel: String
    },
    emits: ['update:modelValue', 'change', 'input', 'radioChange'],
    setup(props, context) {

        const options = [
            {
                text: "AND",
                value: "and"
            },
            {
                text: "OR",
                value: "or"
            }
        ]

        const and = "and";

        function handleChange(e: any) {
          if (!props.disabled) { //to prevent bFormCheckbox from emitting update on label click
            context.emit('update:modelValue', e); // vue 3 updates v-model
            context.emit('input', e); // updates v-model
            context.emit('change', e); // triggers optional function in parent
          }
        }

        function handleRadioChange(e: any) {
            context.emit('radioChange', e); // triggers optional function in parent
        }

        return {
            handleChange,
            handleRadioChange,
            options,
            and
        }

    }
})

