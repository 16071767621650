import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-212c5173"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group search-type-and-or input-standard-size" }
const _hoisted_2 = {
  key: 0,
  class: "col-form-label"
}
const _hoisted_3 = {
  key: 1,
  class: "search-type-and-or input-standard-size"
}
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_form_radio_group = _resolveComponent("b-form-radio-group")!
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("fieldset", _hoisted_1, [
      (_ctx.labelTop || _ctx.radioLabel)
        ? (_openBlock(), _createElementBlock("legend", _hoisted_2, [
            _createTextVNode(_toDisplayString(_ctx.radioLabel) + _toDisplayString(_ctx.label) + " ", 1),
            (_ctx.moreInfo)
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 0,
                  icon: "fa-solid fa-info-circle",
                  "popover.hover.top": "moreInfo"
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.radioLabel)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_b_form_radio_group, {
              options: _ctx.options,
              modelValue: _ctx.and,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.and) = $event)),
              onInput: _ctx.handleRadioChange
            }, null, 8, ["options", "modelValue", "onInput"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_b_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_b_col, { class: "noPadding" }, {
          default: _withCtx(() => [
            _createVNode(_component_b_form_checkbox, {
              disabled: _ctx.disabled,
              readonly: _ctx.readonly,
              modelValue: _ctx.modelValue,
              onInput: _ctx.handleChange,
              class: "custom-checkbox"
            }, {
              default: _withCtx(() => [
                (!_ctx.labelTop)
                  ? (_openBlock(), _createElementBlock("label", _hoisted_4, _toDisplayString(_ctx.label), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["disabled", "readonly", "modelValue", "onInput"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}