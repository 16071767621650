// Deprecated. Use maintenance store's qaStatuses paths to create options
export enum QAStatusEnum {
    SALES_ENTRY = "sales entry",
    PROJECT_ENTERED = "project entered",
    IN_DESIGN = "in design",
    IN_CHECK_DOC = "in check doc",
    RELEASED_BY_DESIGNER = "released by designer",
    ESTIMATION_POOL = "estimation pool",
    IN_ESTIMATION = "in estimation",
    IN_SAMPLE = "in sample",
    COMPLETE = "complete",
    IN_DFM = "in dfm",
    DESIGN_NEEDS_FURTHER_REVIEW = "design needs further review",
    IN_MAJOR_OPS_DESIGN_REVIEW = "in major ops design review",
    IN_MAJOR_OPS_ESTIMATION_REVIEW = "in major ops estimation review",
    IN_FOR_MAJOR_OPS_IPR_REVIEW = "in for major ops ipr review",
    VOIDED = "voided"
}

export type Option = {
    text: string;
    value: string | number;
    disabled?: boolean;
}

export const effortCodeOptions: Option[] = [
    {
        text: "QUICK",
        value: "quick"
    },
    {
        text: "EASY",
        value: "easy"
    },
    {
        text: "MEDIUM",
        value: "medium"
    },
    {
        text: "HARD",
        value: "hard"
    },
    {
        text: "EXTREMELY HARD",
        value: "extremely hard"
    }
]

export const urgencyOptions: Option[] = [
    {
        text: "None",
        value: ""
    },
    {
        text: "Urgent",
        value: "urgent"
    },
    {
        text: "Standard",
        value: "standard"
    },
    {
        text: "Customer Priority",
        value: "customer priority"
    },
    {
        text: "Auto Build Priority",
        value: "auto build priority"
    }
]

export const qaOptions: Option[] = [
    {
        text: "Yes",
        value: "yes",
    },
    {
        text: "No",
        value: "no",
    }
]

export const componentTypeOptions: Option[] = [
    {
        text: "Component",
        value: "Component"
    },
    {
        text: "Purchased",
        value: "Purchased"
    }
]
// Deprecated. Use maintenance store's qaStatuses paths to create options
export const qaStatusOptions: Option[] = [
    {
        text: "Sales Entry",
        value: QAStatusEnum.SALES_ENTRY,
    },
    {
        text: "Project Entered",
        value: QAStatusEnum.PROJECT_ENTERED,
    },
    {
        text: "In Design",
        value: QAStatusEnum.IN_DESIGN
    },
    {
        text: "In Check Doc",
        value: QAStatusEnum.IN_CHECK_DOC,
    },
    {
        text: "Released by Designer",
        value: QAStatusEnum.RELEASED_BY_DESIGNER,
    },
    {
        text: "Estimation Pool",
        value: QAStatusEnum.ESTIMATION_POOL,
    },
    {
        text: "In Estimation",
        value: QAStatusEnum.IN_ESTIMATION,
    },
    {
        text: "In Sample",
        value: QAStatusEnum.IN_SAMPLE,
    },
    {
        text: "Complete",
        value: QAStatusEnum.COMPLETE,
    },
    {
        text: "In DFM",
        value: QAStatusEnum.IN_DFM,
    },
    {
        text: "Design needs further review",
        value: QAStatusEnum.DESIGN_NEEDS_FURTHER_REVIEW,
    },
    {
        text: "In Major Ops Design review",
        value: QAStatusEnum.IN_MAJOR_OPS_DESIGN_REVIEW
    },
    {
        text: "In Major Ops Estimation review",
        value: QAStatusEnum.IN_MAJOR_OPS_ESTIMATION_REVIEW
    },
    {
        text: "In for Major Ops IPR review",
        value: QAStatusEnum.IN_FOR_MAJOR_OPS_IPR_REVIEW
    },
    {
        text: "Voided",
        value: QAStatusEnum.VOIDED
    }
]

export const searchProposalByOptions: Option[] = [
    {
        text: "Search By",
        value: "",
        disabled: true,
    },
    {
        text: "Product #",
        value: "product-number",
    },
    {
        text: "Designer",
        value: "designer",
    },
    {
        text: "Customer",
        value: "customer",
    },
    {
        text: "Status",
        value: "status",
    },
    {
        text: "Dunnage Type",
        value: "dunnage-type"
    }
]

// Deprecated. Use maintenance store's routing paths to create options
export const hsOptions: Option[] = [
    {
        text: "N/A",
        value: "N/A"
    },
    {
        text: "P | Pioneer",
        value: "P"
    },
    {
        text: "C | Clamshell",
        value: "C"
    },
    {
        text: "M | Marumatsu",
        value: "M"
    },
    {
        text: "F | Foam Die Cut",
        value: "F"
    },
    {
        text: "FW | Foam WJC",
        value: "FW"
    },
    {
        text: "FS | Foam Saw",
        value: "FS"
    },
    {
        text: "S | Slitter Only",
        value: "S"
    }
]

export const fsOptions: Option[] = [
    {
        text: "N/A",
        value: "N/A"
    },
    {
        text: "P | Part",
        value: "P"
    },
    {
        text: "S | Slab",
        value: "S"
    }
]

export const webbingOptions: Option[] = [
    {
        text: "No Gap (0)",
        value: 0
    },
    {
        text: "Default Gap (.25)",
        value: 0.25
    }
]

// Deprecated. Use maintenance store's routing paths to create options
export const epOptions: Option[] = [
    {
        text: "N/A",
        value: "N/A"
    },
    {
        text: "H | Heat Sealed",
        value: "H"
    },
    {
        text: "S | Stitched",
        value: "S"
    },
    {
        text: "W | Wrapped Edge",
        value: "W"
    }
]

export const reasonCodeOptions: Option[] = [
    {
        text: "100 | NEW-PRINT/QUOTE/SAMPLE",
        value: "100"
    },
    {
        text: "115 | NEW-DENSITY STUDY/CONCEPT",
        value: "115"
    },
    {
        text: "130 | NEW-DESIGN ONLY/SAMPLE-NO QUOTE",
        value: "130"
    },
    {
        text: "200 | REV-DESIGN/COST/SAMPLE",
        value: "200"
    },
    {
        text: "230 | REV-DESIGN ONLY/SAMPLE-NO QUOTE",
        value: "230"
    },
    {
        text: "300 | COST UPDATE",
        value: "300"
    },
    {
        text: "307 | COST-GM BLANKET/PRICE LIST",
        value: "307"
    },
    {
        text: "313 | COST-MFG LOCATION CHANGE",
        value: "313"
    },
    {
        text: "320 | COST/MINOR DESIGN CHANGE",
        value: "320"
    },
    {
        text: "350 | ECN-COST UPDATE",
        value: "350"
    },
    {
        text: "400 | SAMPLE ONLY",
        value: "400"
    },
    {
        text: "405 | BILLABLE PROTOTYPE\\RUN NO DFM",
        value: "405"
    },
    {
        text: "530 | UG-DESIGN ONLY",
        value: "530"
    },
    {
        text: "535 | CATIA-DESIGN ONLY",
        value: "535"
    },
    {
        text: "600 | BUDGETARY PRICE ONLY",
        value: "600"
    },
    {
        text: "610 | NEW-CUST SUPPLY RACK PRINT/QUOTE/SAMPLE",
        value: "610"
    },
    {
        text: "900 | DFM (COST/PRINT)",
        value: "900"
    },
    {
        text: "901 | SUB DFM (COST/PRINT/SAMPLE)",
        value: "901"
    },
    {
        text: "902 | NO PHYSICAL DFM (COST/PRINT)",
        value: "902"
    },
    {
        text: "903 | SUB NO PHYSICAL DFM (COST/PRINT)",
        value: "903"
    },
    {
        text: "905 | DFM-PROTOTYPE (COST/PRINT/SAMPLE)",
        value: "905"
    }
]

export const plantOptions: Option[] = [
    {
        text: "4005 | ORBIS - MENTOR PLANT",
        value: "4005"
    },
    {
        text: "4007 | ORBIS DETROIT SALES & SERVICES",
        value: "4007"
    },
    {
        text: "4008 | ORBIS - OUTSIDE MOLDERS",
        value: "4008"
    },
    {
        text: "4026 | ORBIS - GREENVILLE PLANT",
        value: "4026"
    },
    {
        text: "4027 | ORBIS - PIEDMONT PLANT",
        value: "4027"
    },
    {
        text: "4045 | ORBIS - GEORGETOWN PLANT",
        value: "4045"
    },
    {
        text: "4502 | ORBIS PLASTIC MOLDING DE MEXICO",
        value: "4502"
    },
    {
        text: "4508 | ORBIS PLASTIC MOLDING DE MEXICO",
        value: "4508"
    },
    {
        text: "4978 | ORBIS CANADA LIMITED-OUTSIDE MOLDER",
        value: "4978"
    },
    {
        text: "4010 | ORBIS NORTHWOOD SALES & SERVICES",
        value: "4010"
    }
]

export const fobPlantOptions: Option[] = [
    {
        text: "4005 | ORBIS - MENTOR PLANT",
        value: "4005"
    },
    {
        text: "4007 | ORBIS DETROIT SALES & SERVICES",
        value: "4007"
    },
    {
        text: "4008 | ORBIS - OUTSIDE MOLDERS",
        value: "4008"
    },
    {
        text: "4026 | ORBIS - GREENVILLE PLANT",
        value: "4026"
    },
    {
        text: "4027 | ORBIS - PIEDMONT PLANT",
        value: "4027"
    },
    {
        text: "4045 | ORBIS - GEORGETOWN PLANT",
        value: "4045"
    },
    {
        text: "4502 | ORBIS PLASTIC MOLDING DE MEXICO",
        value: "4502"
    },
    {
        text: "4508 | ORBIS PLASTIC MOLDING DE MEXICO",
        value: "4508"
    },
    {
        text: "4978 | ORBIS CANADA LIMITED-OUTSIDE MOLDER",
        value: "4978"
    },
    {
        text: "4010 | ORBIS NORTHWOOD SALES & SERVICES",
        value: "4010"
    }
]

export const sampleTypeOptions: Option[] = [
    {
        text: "Full",
        value: "Full"
    },
    {
        text: "Full-Partial",
        value: "Full-Partial"
    },
    {
        text: "Partial",
        value: "Partial"
    },
    {
        text: "Prototype",
        value: "Prototype"
    }
]