export default class TimeZoneTranslator {
    /**
     * This function should be run for any data returned back to the DB.
     * Due to a limitation of JDBI, we cannot include timezone information when retrieving data from DB (without screwing up inserts/updates),
     * so here we correct date-time fields by undoing the offset set from toClientTime.
     **/
    public static toServerTime(val: string) {
        if (val) {
            const tmpDate = new Date(val);
            tmpDate.setTime(tmpDate.getTime() + tmpDate.getTimezoneOffset() * 60 * 1000);
            return tmpDate.toISOString();
        }
        return '';
    }

    /**
     * This function should be run for any data returned from DB.
     * Due to a limitation of JDBI, we cannot include timezone information when retrieving data from DB (without screwing up inserts/updates),
     * so here we correct date-time fields by treating them as UTC instead of LocalTime.
     **/
    public static toClientTime(val: string) {
        if (val) {
            const tmpDate = new Date(val);
            tmpDate.setTime(tmpDate.getTime() - tmpDate.getTimezoneOffset() * 60 * 1000);
            return tmpDate.toISOString();
        }
        return '';
    }
}