import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18c03ec6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: {"margin":"auto"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_progress = _resolveComponent("loading-progress")!
  const _component_loading = _resolveComponent("loading")!
  const _component_re_auth = _resolveComponent("re-auth")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_profile_summary = _resolveComponent("profile-summary")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_b_navbar = _resolveComponent("b-navbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_side_bar_nav = _resolveComponent("side-bar-nav")!
  const _component_alert = _resolveComponent("alert")!

  return (_openBlock(), _createElementBlock("div", {
    id: "app",
    class: _normalizeClass({'busy': _ctx.loadingStore.isBusy })
  }, [
    (_ctx.loadingStore.isLoadingProgress)
      ? (_openBlock(), _createBlock(_component_loading_progress, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.loadingStore.isLoading)
      ? (_openBlock(), _createBlock(_component_loading, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.profileStore.reauthRequired)
      ? (_openBlock(), _createBlock(_component_re_auth, { key: 2 }))
      : _createCommentVNode("", true),
    (!_ctx.state.routesHideSideAndNavBar.includes(_ctx.$route.name))
      ? (_openBlock(), _createBlock(_component_b_navbar, {
          key: 3,
          type: "dark",
          class: "orbis-nav",
          toggleable: "lg"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "fa-solid fa-list",
              class: "btn-toggle-nav",
              onClick: _ctx.toggleNav
            }, null, 8, ["onClick"]),
            (!_ctx.state.routesHideProposalInfo.includes(_ctx.$route.name))
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  (_openBlock(), _createElementBlock("span", {
                    class: "proposalInfo",
                    key: _ctx.proposalStore.proposal.salesforceProposalData.customerName
                  }, "Customer: " + _toDisplayString(_ctx.proposalStore.proposal.salesforceProposalData.customerName), 1)),
                  (_openBlock(), _createElementBlock("span", {
                    class: "proposalInfo",
                    key: _ctx.proposalStore.proposal.customerPartDesc
                  }, " | Description: " + _toDisplayString(_ctx.proposalStore.proposal.customerPartDesc), 1)),
                  (_openBlock(), _createElementBlock("span", {
                    class: "proposalInfo",
                    key: _ctx.proposalStore.proposal.proposalNumber
                  }, " | Proposal: " + _toDisplayString(_ctx.proposalStore.proposal.proposalNumber), 1)),
                  (_openBlock(), _createElementBlock("span", {
                    class: "proposalInfo",
                    key: _ctx.proposalStore.proposal.product.productNumber
                  }, " | Product: " + _toDisplayString(_ctx.proposalStore.proposal.product.productNumber), 1))
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_b_dropdown, {
              "no-caret": "",
              left: ""
            }, {
              "button-content": _withCtx(() => [
                _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-user" })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_profile_summary, { "has-footer-slot": "" }, {
                  "footer-content": _withCtx(() => [
                    _createVNode(_component_b_button, { onClick: _ctx.logout }, {
                      default: _withCtx(() => [
                        _createTextVNode("Log Off")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.loadingStore.isLoadingProgress)
      ? (_openBlock(), _createBlock(_component_side_bar_nav, { key: 4 }, {
          content: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_alert)
  ], 2))
}