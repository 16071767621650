import SidebarLogStore from "@/views/sidebar/store/SidebarLogStore";
import rootStore from "@/store/RootStore";
import LoggableError from "@/service/api/types/LoggableError";
import {ApiDetailResponseProblem} from "@/service/api/types/ApiDetailResponseProblem";
import {APIErrorLogEntry} from "@/dto/APIResponseDTO";

export default class LogService {
    private logStore: SidebarLogStore;

    constructor() {
        this.logStore = rootStore.getInstance().sidebarLogStore;
    }

    public generateLoggableErrorFromAPIResponse(event: ApiDetailResponseProblem, customHeader?: String): LoggableError {
        let header: String = "";
        let body: String = "";

        // Use custom header if provided, otherwise create header from GeneralApiProblem "error" (if exists))
        if(customHeader) {
            header = customHeader;
        } else if(event.error) {
            header = event.error.error? event.error.error : "";
        }

        // If APIResponseDTO "message" exists, attempt to construct error log body from it.
        if(event.message) {
            const apiResponseDTO = event.message;
            // If {key: string, value: string, date: Date} "message" object exists, generate the loggable error body from it
            if(apiResponseDTO.message) {
                if(Array.isArray(apiResponseDTO.message)) {
                    const responseMessages = apiResponseDTO.message as Array<APIErrorLogEntry>;
                    responseMessages.forEach((msgObj) => {
                        body = body.concat(msgObj.key).concat(" " + msgObj.value).concat("\n");
                    })
                } else { // This else block might possibly never be reached, not quite sure if we have the possibility of returning non-array error messages
                    const responseMessage = apiResponseDTO.message as APIErrorLogEntry;
                    body = body.concat(responseMessage.key).concat(" " + responseMessage.value);
                }

            }
            // If payload exists, attempt to add payload to error body
            if(apiResponseDTO.payload) {
                try {
                    const payloadString = apiResponseDTO.payload as string;
                    body = body.concat(body? "\n" : ""); // If NOT an empty string, add a newline character.
                    body = body.concat(payloadString);
                } catch (e) {
                    console.log("Error parsing payload as string when generating LoggableError")
                }
            }
        }

        return new LoggableError({header: header, body: body})
    }

    public async logEvent(event: LoggableError): Promise<boolean> {
        try {
            this.logStore.errors.push(event);
            return true;
        } catch(e: any) {
            console.log(e);
        }
        return false;
    }

    public async clearLog(): Promise<boolean> {
        this.logStore.errors = [];
        return true;
    }
}