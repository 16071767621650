export default class ProposalAction {
    actionID!: number;
    description!: string;
    valid!: boolean;
    commentTypeId!: number;
    comments?: string;
    msg?: string;
    messages: Array<{ key: string, value: string, date: Date }> = [];

    constructor(init?: Partial<ProposalAction>) {
        Object.assign(this, init)
    }
}