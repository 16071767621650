import AllMaterial from "@/domain/maintenance/material/AllMaterial";
import editable from "@/domain/decorators/editable";

export default class ComponentBomItem extends editable {
    componentBomItemID!: number;
    componentBomID!: number;
    lineNumber!: number;
    itemNumber!: string;
    materialDescription!: string;
    costQty1!: number;
    costQty2!: number;
    costQty3!: number;
    costQty4!: number;
    costQty5!: number;
    standardCost!: number;
    scrap!: number;
    qtyPer!: number;
    operationUOfM!: string;
    qtyPerUnits!: number;
    numBlanks!: number;
    numOn!: number;
    rawMatlWidth!: number;
    rawMatlLength!: number;
    rawMatlThickness!: number;
    vendor!: string;
    weightEach!: number;
    weightNet!: number;
    number!: string;
    override_StdCost_Flag!: boolean;
    override_StdCost_1!: number;
    override_StdCost_2!: number;
    override_StdCost_3!: number;
    override_StdCost_4!: number;
    override_StdCost_5!: number;

    allMaterial!: AllMaterial; // Joined between the cmp_bom_items, proposals, and AllMaterials table.

    //Material_Hold_Code table
    allPlantsMaterialHold!: string | undefined;
    allPlantsMaterialHoldSeverityCSS!: string;
    allPlantsMaterialHoldDescription!: string;
    plantSpecificMaterialHold!: string | undefined;
    plantSpecificMaterialHoldSeverityCSS!: string;
    plantSpecificMaterialHoldDescription!: string;

    text!: string;
    blankCalcDnd!: boolean;


    constructor(init?: Partial<ComponentBomItem>) {
        super();
        Object.assign(this, init);
        this.allMaterial = new AllMaterial(init?.allMaterial);
    }

    // Deprecated
    get currentSapCost(): number {
        return this.allMaterial.standardPrice;
    }

}
