import {SaveableSearchDTO} from "@/dto/SaveableSearchDTO";
import axios, {AxiosResponse} from "axios";
import {BASE_API_URL} from "@/config/env";
import ProposalSearchResultDTO from "@/dto/ProposalSearchResultDTO";
import ProposalSearchFilter from "@/components/filters/ProposalSearchFilter";
import ResultSetWithCountDTO from "@/dto/ResultSetWithCountDTO";
import {GetResultSetWithCountDTO} from "@/service/api/types/ApiResponseTypes";
import AllMaterial from "@/domain/maintenance/material/AllMaterial";
import APIResponseDTO from "@/dto/APIResponseDTO";
import MaterialFilter from "@/components/filters/MaterialFilter";
import ProposalSimpleDTO from "@/dto/ProposalSimpleDTO";

export default class SearchApiService {



    public async getTopProposalSearchResults(offset: number) {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/search/getProposals/top/` + offset,
        })

        if (!response.data || response.data.length === 0) return {success: false, resultSet: [], totalMatchedRecords: 0}

        try {
            return new ResultSetWithCountDTO<ProposalSearchResultDTO>(response.data);
        }
         catch {
            return {success: false, resultSet: [], totalMatchedRecords: 0}
        }
    }

    public async getProposalSearchResults(proposalSearchFilter: ProposalSearchFilter): Promise<GetResultSetWithCountDTO<ProposalSearchResultDTO>> {
        const data = JSON.stringify(proposalSearchFilter);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/search/getProposals`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        if (!response.data || response.data.length === 0) return {success: false, resultSet: [], totalMatchedRecords: 0}

        try {
            return new ResultSetWithCountDTO<ProposalSearchResultDTO>(response.data);
        }
        catch {
            return {success: false, resultSet: [], totalMatchedRecords: 0}
        }
    }

    public async saveProposalSearch(dto: SaveableSearchDTO): Promise<{success: boolean, searchID: number}> {
        const data = JSON.stringify(dto);
        const response: AxiosResponse = await axios({
            method: "POST",
            url: `${BASE_API_URL}/search/save`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
        if(response.status == 200) {
            return {success: true, searchID: response.data};
        } else {
            return {success: false, searchID: 0};
        }
    }

    public async updateSearchSharePermissions(dto: SaveableSearchDTO): Promise<{success: boolean}> {
        const data = JSON.stringify(dto);
        const response: AxiosResponse = await axios({
            method: "POST",
            url: `${BASE_API_URL}/search/shared/update`,
            data,
            headers: {
                'Content-type': 'application/json'
            }
        })
        if(response.status == 200) {
            return {success: true};
        } else {
            return {success: false};
        }
    }

    public async getAllSearchesWithPermission(currentUser: number): Promise<{success: boolean, ownSearches: SaveableSearchDTO[], sharedSearches: SaveableSearchDTO[]}> {
        const response: AxiosResponse = await axios({
            method: "GET",
            url: `${BASE_API_URL}/search/getAll/${currentUser}`
        })
        if(response.data) {
            return {success: true, ownSearches: response.data[0], sharedSearches: response.data[1]}
        } else {
            return {success: false, ownSearches: [], sharedSearches: []};
        }
    }

    public async getMaterialSearchResults(materialFilter: MaterialFilter): Promise<GetResultSetWithCountDTO<AllMaterial>> {
        const data = JSON.stringify(materialFilter);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/search/material/getMaterials`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        if (!response.data || response.data.length === 0) return {success: false, resultSet: [], totalMatchedRecords: 0}

        try {
            return new ResultSetWithCountDTO<AllMaterial>(response.data);
        }
        catch {
            return {success: false, resultSet: [], totalMatchedRecords: 0}
        }
    }

    public async getDefaultMaterialSearchResults(): Promise<GetResultSetWithCountDTO<AllMaterial>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/search/material/get-all`,
        })

        if (!response.data || response.data.length === 0) return {success: false, resultSet: [], totalMatchedRecords: 0}

        try {
            const apiResponseDTO = new APIResponseDTO<Array<AllMaterial>>(response.data);
            return {success: true, resultSet: apiResponseDTO.payload, totalMatchedRecords: apiResponseDTO.payload.length}
        }
        catch {
            return {success: false, resultSet: [], totalMatchedRecords: 0}
        }
    }

    public async getProposalSimpleSearchResults(): Promise<GetResultSetWithCountDTO<ProposalSimpleDTO>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/search/production/product-lookup/get-all`,
        })

        if (!response.data || response.data.length === 0) return {success: false, resultSet: [], totalMatchedRecords: 0}

        try {
            return new ResultSetWithCountDTO<ProposalSimpleDTO>(response.data);
        }
        catch {
            return {success: false, resultSet: [], totalMatchedRecords: 0}
        }
    }
}