import SideBarMenuItem from "./domain/SideBarMenuItem";
import SideBarMenuChild from "@/views/sidebar/domain/SideBarMenuChild";

class SideBarNavItemManager {
    public menuItems: SideBarMenuItem[] = [];

    constructor() {
        this.updateMenuItems()
    }

    public updateMenuItems() {
        this.menuItems = [
            {
                id: 1,
                title: 'MENU',
                type: SideBarMenuItem.TYPE.HEADER,
                show: true
            },
            {
                id: 2,
                title: 'Advanced Search',
                icon: 'fa-solid fa-magnifying-glass',
                routeName: 'AdvancedSearch',
                type: SideBarMenuItem.TYPE.BUTTON,
                show: true,
            },
            {
                id: 3,
                title: 'Search',
                //icon: 'search',
                icon: 'fa-solid fa-magnifying-glass',
                routeName: 'Search',
                type: SideBarMenuItem.TYPE.BUTTON,
                show: true,
            },

            {
                id: 5,
                title: 'ADMIN',
                type: SideBarMenuItem.TYPE.HEADER,
                show: true
            },
            {
                id: 6,
                title: 'Maintenance',
                // icon: 'tools',
                icon: 'fa-solid fa-screwdriver-wrench',
                type: SideBarMenuItem.TYPE.BUTTON,
                routeName: 'EditMaintenanceItemsScreen',
                show: true,
            },
            {
                id: 7,
                title: 'Workflow',
                type: SideBarMenuItem.TYPE.BUTTON,
                routeName: 'WorkflowScreen',
                icon: 'fa-screwdriver-wrench',
                show: false
            },
            {
              id: 8,
              title: 'Designer Skills',
              type: SideBarMenuItem.TYPE.BUTTON,
              routeName: 'DesignerSkills',
              icon: 'fa-screwdriver-wrench',
              show: false
            },
            {
                id: 9,
                title: 'Customers & Products',
                type: SideBarMenuItem.TYPE.BUTTON,
                routeName: 'CustomerProducts',
                icon: 'fa-screwdriver-wrench',
                show: false
            },
            {
                id: 23,
                title: 'Location Manager',
                type: SideBarMenuItem.TYPE.BUTTON,
                routeName: 'UserLocations',
                icon: 'fa-screwdriver-wrench',
                show: false
            },
            {
                id: 10,
                title: 'PROPOSAL',
                type: SideBarMenuItem.TYPE.HEADER,
                show: true
            },
            {
                id: 11,
                title: 'Header',
                icon: 'fa-book',
                type: SideBarMenuItem.TYPE.BUTTON,
                routeName: 'ProposalHeader',
                show: false
            },
            {
                id: 12,
                title: 'Scheduling',
                icon: 'fa-calendar',
                type: SideBarMenuItem.TYPE.BUTTON,
                routeName: 'ProposalScheduling',
                show: false
            },
            {
                id: 13,
                title: 'Text Maintenance',
                icon: 'fa-pencil',
                type: SideBarMenuItem.TYPE.BUTTON,
                routeName: 'ProposalTextMaintenance',
                show: false
            },
            {
                id: 14,
                title: 'Sample',
                icon: 'fa-dolly',
                type: SideBarMenuItem.TYPE.BUTTON,
                routeName: 'ProposalSample',
                show: false
            },
            {
                id: 15,
                title: 'Costing',
                icon: 'fa-table-list',
                type: SideBarMenuItem.TYPE.PARENT,
                routeName: 'ProposalCosting',
                children: [
                    {
                        id: 1,
                        title: 'Components',
                        icon: 'fa-table-list',
                        type: SideBarMenuChild.TYPE.PARENT,
                        activeRouteNameArray: ['ProposalCosting'],
                        routeName: 'ComponentBom',
                        children: [
                            {
                                id: 1,
                                title: 'Component Bom Items',
                                type: SideBarMenuChild.TYPE.BUTTON,
                                activeRouteNameArray: ['CmpBomItems'],
                                routeName: 'CmpBomItems',
                                key: 'cmpbomitems',
                                show: true
                            },
                            {
                                id: 2,
                                title: 'Route Items',
                                type: SideBarMenuChild.TYPE.BUTTON,
                                activeRouteNameArray: ['CmpRouteItems'],
                                routeName: 'CmpRouteItems',
                                key: 'RouteItems',
                                show: true
                            },
                            {
                                id: 3,
                                title: 'Tooling Items',
                                type: SideBarMenuChild.TYPE.BUTTON,
                                activeRouteNameArray: ['CmpToolingItems'],
                                routeName: 'CmpToolingItems',
                                key: 'toolingitems',
                                show: true
                            }
                        ],
                        key: 'compBom',
                        show: true
                    },
                    {
                        id: 2,
                        title: 'Purchased Items',
                        type: SideBarMenuChild.TYPE.BUTTON,
                        activeRouteNameArray: ['PurchasedItem'],
                        routeName: 'PurchasedItem',
                        key: 'prodPurchasedItem',
                        show: true
                    },
                    {
                        id: 3,
                        title: 'Assembly Route',
                        type: SideBarMenuChild.TYPE.BUTTON,
                        activeRouteNameArray: ['AssemblyRoute'],
                        routeName: 'AssemblyRoute',
                        key: 'prodRouteItems',
                        show: true
                    },
                    {
                        id: 4,
                        title: 'Amortized',
                        type: SideBarMenuChild.TYPE.BUTTON,
                        activeRouteNameArray: ['AmortizedTable'],
                        routeName: 'AmortizedTable',
                        key: 'prodAmortItems',
                        show: true
                    }
                ],
                show: false
            },
            {
                id: 16,
                title: 'Sub Assemblies',
                icon: 'fa-table-list',
                type: SideBarMenuItem.TYPE.BUTTON,
                routeName: 'SubAssemblies',
                show: false
            },
            {
                id: 17,
                title: 'Component Totals',
                icon: 'fa-receipt',
                type: SideBarMenuItem.TYPE.BUTTON,
                routeName: 'ProposalComponentTotals',
                show: false
            },
            {
                id: 18,
                title: 'COST',
                icon: 'fa-money-bill',
                type: SideBarMenuItem.TYPE.BUTTON,
                routeName: 'ProposalCost',
                show: false
            },
            {
                id: 19,
                title: 'Reports',
                icon: 'fa-table-list',
                type: SideBarMenuItem.TYPE.BUTTON,
                routeName: 'ProposalReports',
                show: false
            },
            {
                id: 20,
                title: 'COPY',
                type: SideBarMenuItem.TYPE.HEADER,
                show: true
            },
            {
                id: 21,
                title: 'copy',
                type: SideBarMenuItem.TYPE.BUTTON,
                routeName: 'CopyProposal',
                icon: 'fa-layer-group',
                show: true
            },
            {
                id: 22,
                title: 'Design Queue',
                type: SideBarMenuItem.TYPE.BUTTON,
                routeName: 'DesignQueue',
                icon: 'fa-table-list',
                show: false
            },
            {
                id: 23,
                title: 'Designer Queue',
                type: SideBarMenuItem.TYPE.BUTTON,
                routeName: 'DesignerQueue',
                icon: 'fa-table-list',
                show: false
            },
            {
                id: 24,
                title: 'Production Orders',
                type: SideBarMenuItem.TYPE.BUTTON,
                routeName: 'ProductionOrders',
                icon: 'fa-table-list',
                show: true
            },
            {
                id: 24,
                title: 'Production',
                type: SideBarMenuItem.TYPE.BUTTON,
                routeName: 'Production',
                icon: 'fa-table-list',
                show: true
            }
        ];
    }

    public showProposalNav() {
        this.updateMenuTreeByRoute('ProposalHeader', true);
        this.updateMenuTreeByRoute('ProposalScheduling', true);
        this.updateMenuTreeByRoute('ProposalTextMaintenance', true);
        this.updateMenuTreeByRoute('ProposalSample', true);
        this.updateMenuTreeByRoute('ProposalCosting', true);
        this.updateMenuTreeByRoute('SubAssemblies', true);
        this.updateMenuTreeByRoute('ProposalComponentTotals', true);
        this.updateMenuTreeByRoute('ProposalCost', true);
        this.updateMenuTreeByRoute('ProposalReports', true);
    }

    public hideProposalNav() {
        this.updateMenuTreeByRoute('ProposalHeader', false);
        this.updateMenuTreeByRoute('ProposalScheduling', false);
        this.updateMenuTreeByRoute('ProposalTextMaintenance', false);
        this.updateMenuTreeByRoute('ProposalSample', false);
        this.updateMenuTreeByRoute('ProposalCosting', false);
        this.updateMenuTreeByRoute('SubAssemblies', false);
        this.updateMenuTreeByRoute('ProposalComponentTotals', false);
        this.updateMenuTreeByRoute('ProposalCost', false);
        this.updateMenuTreeByRoute('ProposalReports', false);
    }

    private updateMenuTreeByRoute(name: string, value: boolean) {
        let menuItem = this.menuItems.find(item => item.routeName === name);
        if (menuItem) {
            menuItem.show = value;
        }
    }

}

const sideBarNavItemManager = new SideBarNavItemManager();
export default sideBarNavItemManager;
