import TimeZoneTranslator from "@/utility/TimeZoneTranslator";

export default class ProposalCommentLog {
    public commentLogId!: number;
    public commentId!: number;
    public userId!: number;
    public userName!: string; //display only
    public timeEntered!: string;

    constructor(init?: Partial<ProposalCommentLog>) {
        Object.assign(this, init);
        this.fixLocalTimesToUTC();
    }

    public fixLocalTimesToUTC(): void {
        this.timeEntered = TimeZoneTranslator.toClientTime(this.timeEntered);
    }
}