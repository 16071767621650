export default class APIResponseDTO<T> {
    public payload!: T;
    public message!: APIErrorLogEntry | Array<APIErrorLogEntry>; // We seem to be only returning the Array of errors from API, but maintaining legacy "single" error for now.
    public success!: boolean;

    constructor(init?: Partial<APIResponseDTO<T>>) {
        Object.assign(this, init);
    }
}

export type APIErrorLogEntry = {
    key: string;
    value: string;
    date: Date;
}