import {reactive} from "vue";
import {RootStore} from "@/store/RootStore";
import {store} from "@/store/store-decorators";
import {ApiDetailResponseProblem} from "@/service/api/types/ApiDetailResponseProblem";
import LoggableError from "@/service/api/types/LoggableError";

type SidebarState = {
    sideBarVisible: boolean;
    errors: Array<LoggableError>;
}

@store
export default class SidebarLogStore {

    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }
    // state
    private state = reactive<SidebarState>({
        sideBarVisible: false,
        errors: []
    })

    private props = {
        sideBarWidthDesktop: 255,
        sideBarWidthMobile: 300,
        mobileDesktopBreak: 900,
        // not sure how im going to handle this, but the side bar stays open if the screen width is less than this number
        // in this application i dont think we'll ever want the side bar to dynamically open
        keepOpenWidth: 9999999,
    }

    // getters
    get sideBarVisible(): boolean {
        return this.state.sideBarVisible;
    }
    get sideBarWidthDesktop(): number {
        return this.props.sideBarWidthDesktop
    }
    get sideBarWidthMobile(): number {
        return this.props.sideBarWidthMobile
    }
    get mobileDesktopBreak(): number {
        return this.props.mobileDesktopBreak
    }

    // actions
    set sideBarVisible(value: boolean) {
        this.state.sideBarVisible = value;
    }

    get errors(): Array<LoggableError> {
        return this.state.errors;
    }

    set errors(value: Array<LoggableError>) {
        this.state.errors = value;
    }

    public toggleSidebar(): void {
        this.state.sideBarVisible = !this.state.sideBarVisible;

        if (this.state.sideBarVisible) {
            sessionStorage.setItem('sidebarlog.sideBarVisible', 'true');
        } else {
            sessionStorage.setItem('sidebarlog.sideBarVisible', 'false');
        }
    }
}